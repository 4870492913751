<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="msgSend/web" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('newMsgSend.type', '消息类型')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('newMsgSend.status', '发送状态')">
          <el-select v-model.trim="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('newMsgSend.sendTime', '发送时间')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="type" :label="$l('newMsgSend.type', '消息类型')" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'msgPush'">
            {{ $l("newMsgSend.msgPush", "APP消息推送") }}
          </span>
          <span v-if="scope.row.type === 'sms'">
            {{ $l("newMsgSend.sms", "手机短信") }}
          </span>
          <span v-if="scope.row.type === 'email'">
            {{ $l("newMsgSend.email", "邮件") }}
          </span>
          <span v-if="scope.row.type === 'wxMsgPush'">
            {{ $l("newMsgSend.wxMsgPush", "小程序消息推送") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="content" :label="$l('newMsgSend.content', '消息内容')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="referBizNo" :label="$l('newMsgSend.referBizNo', '关联单号')" align="center"
        width="120"></el-table-column>
      <el-table-column prop="target" :label="$l('newMsgSend.target', '目标用户')" width="120"></el-table-column>
      <el-table-column prop="status" :label="$l('newMsgSend.status', '发送状态')" width="120">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status === 1">发送成功</el-tag>
          <el-tag type="danger" v-if="scope.row.status === 2">发送失败
            <el-popover v-if="scope.row.note !== '' && scope.row.note !== null" placement="top-start" width="200"
              trigger="hover" :content="scope.row.note" style="color: #0747FD;">
              <i slot="reference" class="el-icon-warning"></i>
            </el-popover>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sendTime" :label="$l('newMsgSend.sendTime', '发送时间')" align="center"
        width="140"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      importLoading: false,
      loading: true,
      dateRange: [],
      search: {
        type: "",
        filter: "",
        status: "",
        startTime: "",
        endTime: "",
        isWeb: true,
      },
      typeOptions: [
        { value: "msgPush", label: this.$l("newMsgSend.msgPush", "APP消息推送") },
        { value: "sms", label: this.$l("newMsgSend.sms", "手机短信") },
        { value: "email", label: this.$l("newMsgSend.email", "邮件") },
        { value: "wxMsgPush", label: this.$l("newMsgSend.wxMsgPush", "小程序消息推送") },
      ],
      statusOptions: [
        { value: 0, label: this.$l("newMsgSend.notSend", "未发送") },
        { value: 1, label: this.$l("newMsgSend.sendSucceed", "发送成功") },
        { value: 2, label: this.$l("newMsgSend.failSend", "发送失败") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
